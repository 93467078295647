import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Head from "../components/head"
import Header from "../components/header"
import Footer from "../components/footer"
import Nav from "../components/nav"
import styles from "../styles/global.css"
import security from "../styles/security.css"
import media from "../styles/media.css"
import { LoginHeader } from "../components/nav"
import { SocialHeader } from "../components/social"

// export default function Home() {
//   return <div>Hello world!</div>
// }
export function Banner_img() {
  return <StaticImage src="../../static/images/jobs-hd.jpg" alt="banner" />
}
export default () => (
  <>
    <Header titleText="Accessibility - Happy Mango" />
    <div className="main">
      <div className="PC_head">
        <LoginHeader />
        <SocialHeader />
        <div className="logo_div">
          <a rel="" href="/" className="logo" title="Happy Mango">
            <img
              className="light"
              src="/images/hm-logo-v2.png"
              alt="Happy Mango"
            />
          </a>
        </div>
        <ul className="nav_ul">
          <li className="nav_li">
            <a rel="" href="/services/">
              Our Services
            </a>
            <ul className="nav_ul_son">
              <li className="nav_li_son">
                <a rel="" href="/services/#Digitalize">
                  Front to Back Digitalization
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Customize">
                  Customized Underwriting
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Expand">
                  Digital Marketing Tools
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Improve">
                  Financial Health Management
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Track">
                  Impact Analysis and Reporting
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/clients/">
              Our Clients
            </a>
            <ul className="nav_ul_son nav_ul_son_se">
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Banks
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Credit Unions
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  CDFIs
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/why/">
              Why Happy Mango?
            </a>
          </li>
        </ul>
      </div>
      <Head />
      <div className="banner_contact">
        <img
          className="contact_img"
          src="/images/flower-crop.jpg"
          alt="banner"
        />
        <h1 className="bannerinfo accessibility">ACCESSIBILITY STATEMENT</h1>
      </div>

      <div className="security privacy">
        <div className="item">
          <div className="excerpt">
            <div className="title">General</div>
            <p>
              Happy Mango strives to ensure that its services are accessible to
              people with disabilities. Happy Mango has invested a significant
              amount of resources to help ensure that its website is made easier
              to use and more accessible for people with disabilities, with the
              strong belief that every person has the right to live with
              dignity, equality, comfort and independence.
            </p>

            <div className="title">
              Accessibility on https://www.happymangocredit.com/
            </div>
            <p>
              Happy Mango makes available the{" "}
              <a
                rel=""
                href="https://UserWay.org"
                alt="Free Website Accessibility Widget"
                title="Free Website Accessibility Widget"
              >
                UserWay Website Accessibility Widget
              </a>{" "}
              that is powered by a dedicated accessibility server. The software
              allows Happy Mango to improve its compliance with the Web Content
              Accessibility Guidelines (WCAG 2.1).
            </p>

            <div className="title">Enabling the Accessibility Menu</div>
            <p>
              The https://www.happymangocredit.com/ accessibility menu can be
              enabled by clicking the accessibility menu icon that appears on
              the corner of the page. After triggering the accessibility menu,
              please wait a moment for the accessibility menu to load in its
              entirety.
            </p>

            <div className="title"></div>
            <p>
              Happy Mango continues its efforts to constantly improve the
              accessibility of its site and services in the belief that it is
              our collective moral obligation to allow seamless, accessible and
              unhindered use also for those of us with disabilities.
            </p>

            <p>
              Despite our efforts to make all pages and content on
              https://www.happymangocredit.com/ fully accessible, some content
              may not have yet been fully adapted to the strictest accessibility
              standards. This may be a result of not having found or identified
              the most appropriate technological solution.
            </p>

            <div className="title">Here For You</div>
            <p>
              If you are experiencing difficulty with any content on
              https://www.happymangocredit.com/ or require assistance with any
              part of our site, please contact us during normal business hours
              as detailed below and we will be happy to assist.
            </p>

            <div className="title">Contact Us</div>
            <p>
              If you wish to report an accessibility issue, have any questions
              or need assistance, please contact Happy Mango Customer Support.
            </p>
            <p>&nbsp;</p>
          </div>
        </div>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  </>
)
